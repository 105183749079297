import React, { useContext, useEffect, useState, useRef } from "react";
import { HTTP_STATUS_CODES, getAuthRequest } from "../../../api/API";
import { useFormFieldOnChange } from "../../../utils/FormHooks";
import {
  applyGVD,
  getBricks,
  getClasses,
  getFamilies,
  getGvdDetail,
  getGvdGrouping,
  getGvdVariant,
  getSegments,
} from "./ReclassifyAPI";
import axios from "axios";

import styled from "styled-components";
import { DashboardPopUpContext } from "../../../dashboard-components/context/DashboardPopUpContext";
import { getToken } from "../../../provider/AuthProvider";
const ActionsContainer = styled.div`
  & > button:not(:last-child) {
    margin-right: 5px;
  }
  text-align: center;
`;

const ActionButton = styled.button`
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border: none;
  background-color: #707070;
  color: white;
  font-size: 12px;
  cursor: pointer;
  border-radius: 15px;
`;

const Dropdown = styled.select`
  padding: 2px;
`;

const DropdownContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  margin-bottom: 10px;

  & div:first-child {
    display: flex;
    flex-direction: row;

    & select {
      flex-grow: 1;
    }
  }

  & span.title {
    margin-right: 5px;
    width: 70px;
    display: inline-block;
    font-weight: bold;
    &::after {
      content: ":";
    }
  }

  & span.description {
    grid-column-start: 1;
    grid-column-end: 2;
    font-size: 14px;
    font-style: italic;
  }
`;

const ReclassifyDropdownsModal = ({
  productToBeUpdated,
  productScanned,
  setLoading,
  setErrorMessage,
  refreshCallback,
  closeClassifyModal,
  children,
  handleSelectedRef,
}) => {
  console.log("product to be updated", productToBeUpdated?.GTIN);
  // variables to store the default values
  const initialSegmentValue = productScanned?.SegmentID;
  const initialFamilyValue = productScanned?.FamilyID;
  const initialClassValue = productScanned?.ClassID;
  const initialBrickValue = productScanned?.BrickID;
  const initialGroupingValue = productScanned?.GroupingID;
  const initialVariantValue = productScanned?.VariantID;
  const initialDetailValue = productScanned?.DetailID;
  // use effect for families section
  console.log("the product scaneed ", productScanned);
  console.log("the initial brick value", initialBrickValue);

  const [segmentDropDown, setSegmentDropDown] = useState([]);
  const [segment, setSegment] = useState(initialSegmentValue);
  const [familyDropDownOptions, setFamilyDropdownOptions] = useState([]);
  const [family, setFamily] = useState(initialFamilyValue);
  console.log("the usestate family", family)
  const [classDropDownOptions, setClassDropDownOptions] = useState([]);
  const [classs, setClasss] = useState(initialClassValue);
  console.log("the usestate class", classs)
  const [brickDropDownOptions, setBrickDropDownOptions] = useState([]);
  const [brick, setBrick] = useState(initialBrickValue);
  console.log("the usestate brick", brick);
  const [groupingDropDownOptions, setGroupingDropDownOptions] = useState([]);
  const [grouping, setGrouping] = useState(initialGroupingValue);
  //const [grouping, setGrouping] = useState(null);
  const [variantDropDownOptions, setVariantDropDownOptions] = useState([]);
  const [variant, setVariant] = useState(initialVariantValue);
  //const [variant, setVariant] = useState(null);
  const [detailDropDownOptions, setDetailDropDownOPtions] = useState([]);
  const [detail, setDetail] = useState(initialDetailValue);
  //const [detail, setDetail] = useState(null);
  // state to activate the confirm button
  const [activated, setActivated] = useState();
  const [message, setMessage] = useState(false);
  const selectedBrickRef = useRef(null);
  const groupingSelectedRef = useRef(null);
  const isMountedRef = useRef(true);

  useEffect(() => {
    const fetchData = async (updateRequestNumber) => {
      const res = await getSegments();
      console.log("segment resis", res.data);

      // Check if select option exists in the data
      const selectOptionExists = res.data.some(
        (item) => item.Desc === "-- SELECT --"
      );

      // Add select option manually if it doesn't exist
      if (!selectOptionExists) {
        const selectOption = { ID: "", Desc: "-- SELECT --" };
        res.data.unshift(selectOption);
      }

      setSegmentDropDown(res.data);
    };

    fetchData();
    if (!family) {

      setActivated(true);
    } else {
      setActivated(false);
    }
  }, []);

 

  // useEffect(() => {
  //   // let isCancelled = false;

  //   // const brickVlalueFromFamily = selectedBrickRef.current.value;
  //   // Thi is the rest functionality for the dropdowns
  //   if (family || classs || brick || grouping || variant || detail) {
  //     // setFamily();
  //     // setGrouping();
  //     // setBrick();
  //     // setClasss();
  //     // setVariant();
  //     // setDetail();
  //     setFamilyDropdownOptions([]);
  //     setGroupingDropDownOptions([]);
  //     setBrickDropDownOptions([]);
  //     setClassDropDownOptions([]);
  //     setVariantDropDownOptions([]);
  //     setDetailDropDownOPtions([]);
      
  //   }
    
  //   const fetchFamily = async () => {
  //     const res = await getFamilies(segment);
  //     console.log("families are", res.data);

  //     // Check if select option exists in the data
  //     const selectOptionExists = res.data.some(
  //       (item) => item.Desc === "-- SELECT --"
  //     );
  //     // Add select option manually if it doesn't exist
  //     if (!selectOptionExists) {
  //       const selectOption = { ID: "", Desc: "-- SELECT --" };
  //       res.data.unshift(selectOption);
  //     }
  //     console.log("families are", res.data);
  //     setFamilyDropdownOptions(res.data);
  //   };

  //   fetchFamily();
  //   setGrouping();
  //   setBrick();
  //   //setClasss();
  //   setVariant();
  //   setDetail();
  //   family ? setActivated(true) : setActivated(false);
  // }, [segment]);
  // In useEffect for families section, modify it like this:
    useEffect(() => {
        if (family || classs || brick || grouping || variant || detail) {
          setFamilyDropdownOptions([]);
          setGroupingDropDownOptions([]);
          setBrickDropDownOptions([]);
          setClassDropDownOptions([]);
          setVariantDropDownOptions([]);
          setDetailDropDownOPtions([]);
        }
        
        const fetchFamily = async () => {
          try {
            // Only fetch if segment has a value
            if (!segment) {
              setFamilyDropdownOptions([]);
              return;
            }

            const res = await getFamilies(segment);
            
            // Check if res and res.data exist
            if (res && res.data) {
              // Check if select option exists in the data
              const selectOptionExists = res.data.some(
                (item) => item.Desc === "-- SELECT --"
              );

              // Add select option manually if it doesn't exist
              if (!selectOptionExists) {
                const selectOption = { ID: "", Desc: "-- SELECT --" };
                res.data.unshift(selectOption);
              }

              setFamilyDropdownOptions(res.data);
            } else {
              // If no data, set empty array
              setFamilyDropdownOptions([]);
            }
          } catch (error) {
            console.error("Error fetching families:", error);
            setFamilyDropdownOptions([]);
          }
        };

        fetchFamily();
        setGrouping();
        setBrick();
        setVariant();
        setDetail();
        family ? setActivated(true) : setActivated(false);
    }, [segment]);

  //use Effect for the classes section
  useEffect(() => {
    if (classs || brick || grouping || variant || detail) {
      setBrickDropDownOptions([]);
      setClassDropDownOptions([]);
      setVariantDropDownOptions([]);
      setDetailDropDownOPtions([]);
      setGroupingDropDownOptions([]);
      // setActivated(true);
    }
    const fetchClasses = async () => {
      const res = await getClasses(family);

      // Check if select option exists in the data
      const selectOptionExists = res.data.some(
        (item) => item.Desc === "-- SELECT --"
      );
      // Add select option manually if it doesn't exist
      if (!selectOptionExists) {
        const selectOption = { ID: "", Desc: "-- SELECT --" };
        res.data.unshift(selectOption);
      }
      console.log("classes are", res.data);
      setClassDropDownOptions(res.data);
      // setFamily();
      // setBrickDropDownOptions([]);
    };

    fetchClasses();
    classs ? setActivated(true) : setActivated(false);
  }, [family]);
  // Use effect for brick  section
  useEffect(() => {
    // reset of the other dropdowns below once value changes
    if (classs || brick || grouping || variant || detail) {
      setBrick();
      setBrickDropDownOptions([]);
      setVariantDropDownOptions([]);
      setDetailDropDownOPtions([]);
      // setGroupingDropDownOptions([]);
      setActivated(false);
    }
    const fetchBricks = async () => {
      const res = await getBricks(classs);

      // Check if select option exists in the data
      const selectOptionExists = res.data.some(
        (item) => item.Desc === "-- SELECT --"
      );
      // Add select option manually if it doesn't exist
      if (!selectOptionExists) {
        const selectOption = { ID: "", Desc: "-- SELECT --" };
        res.data.unshift(selectOption);
      }
      console.log("bricks data are", res.data);
      setBrickDropDownOptions(res.data);
    };

    fetchBricks();
    brick ? setActivated(true) : setActivated(false);
  }, [classs]);

  // use EFfect to get the grouping section
  useEffect(() => {
    if (brick || grouping || variant || detail) {
      // setGrouping()
      // setGrouping();
      setVariant(null);
      setDetail(null);
      // setGroupingDropDownOptions([]);
      setVariantDropDownOptions([]);
      setDetailDropDownOPtions([]);
    }
    const fetchGrouping = async () => {
      const res = await getGvdGrouping(brick);
      // Check if select option exists in the data
      const selectOptionExists = res.data.some(
        (item) => item.Desc === "-- SELECT --"
      );
      // Add select option manually if it doesn't exist
      if (!selectOptionExists) {
        const selectOption = { ID: "", Desc: "-- SELECT --" };
        res.data.unshift(selectOption);
      }
      setGroupingDropDownOptions(res.data);
      console.log("grouping is", res.data);
    };
    fetchGrouping();
    setActivated(true)
  }, [brick]);

  // use effect to get variant section
  useEffect(() => {
    if (grouping || variant || detail) {
      //setGrouping(null);
      setVariant(null);
      setDetail(null);
      // setGroupingDropDownOptions([]);
      setVariantDropDownOptions([]);
      setDetailDropDownOPtions([]);
    }
    const fetchVariant = async () => {
      const res = await getGvdVariant(grouping);
      // Check if select option exists in the data
      const selectOptionExists = res.data.some(
        (item) => item.Desc === "-- SELECT --"
      );
      // Add select option manually if it doesn't exist
      if (!selectOptionExists) {
        const selectOption = { ID: "", Desc: "-- SELECT --" };
        res.data.unshift(selectOption);
      }
      setVariantDropDownOptions(res.data);
      console.log("variant is", res.data);
    };
    fetchVariant();
    variant ? setActivated(true) : setActivated(false);
  }, [grouping]);

  // useEFfect to get detail section
  useEffect(() => {
    const fetchDetail = async () => {
      const res = await getGvdDetail(variant);
      // Check if select option exists in the data
      const selectOptionExists = res.data.some(
        (item) => item.Desc === "-- SELECT --"
      );
      // Add select option manually if it doesn't exist
      if (!selectOptionExists) {
        const selectOption = { ID: "", Desc: "-- SELECT --" };
        res.data.unshift(selectOption);
      }
      setDetailDropDownOPtions(res.data);
      console.log("detail is", res.data);
    };
    fetchDetail();
    detail ? setActivated(true) : setActivated(false);
  }, [variant]);

  //get the selected segment
  // and also update our state
  const onChangeSegmentHandler = (e) => {
    setSegment(e.target.value);
    // if value selected, set the selectedSegment to the ID field for segment
    // reset Family, class, brick, grouping variant, details to empty and inactive
    // call the family endpoint and populate the family dropdown
    // make the family active
    // make confirm button inactive
  };
  const onChangeFamilyHandler = (e) => {
    setFamily(e.target.value);
    // reset class, brick, grouping variant, details to empty and inactive
    // call the class endpoint and populate the class dropdown
    // make the class active
    // make confirm button inactive
  };
  const onchangeClassHandler = (e) => {
    setClasss(e.target.value);
  };

  const onChangeBrickHandler = (e) => {
    if (e.target.value !== "" && e.target.value.length >= 1) {
      setBrick(e.target.value);
      //alert(e.target.value);
      setActivated(true);
    }
    //  grouping variant, details to empty and inactive
    // if not --selecdt-- make confirm button active
  };
  const onChangeGroupingHandler = (e) => {
    setGrouping(e.target.value)

   
    
      //I wanna set the grouping value is null or g

  };


  const onChangeVariantHandler = (e) => {
    setVariant(e.target.value);
    // alert(e.target.value);
  };
  const onChangeDetailHandler = (e) => {
    setDetail(e.target.value);
    // alert(e.target.value);
  };

  const onClickConfirm = async () => {
    // this value  will be use to always check the brick value that is set
    const selectedBrick = selectedBrickRef.current.value;

    // if (groupingSelectedRef.current.value){
    //   alert(groupingSelectedRef.current.value)
    // }else{
    //   alert("I dont have a value")
    // }
    //const selectedGrouping = groupingSelectedRef.current.value;
    //alert(groupingSelectedRef.current.value);
    if (!selectedBrick) {
      setMessage(true);
      return
    } 
    
    setLoading(true);
    
    await applyGVD(
      productToBeUpdated.GTIN,
      brick,
      grouping,
      variant,
      detail
    );
    
    setLoading(false);
    closeClassifyModal();
    refreshCallback();
    setMessage(false);
   
  };

  return (
    <div className="flex flex-col"
    >
      <div>
        <div></div>
        <label>Segment:</label>
        {segmentDropDown.length === 0 ? (
          <select className="rounded w-full py-1.5 border border-gray" disabled>
            <option value="">-- SELECT --</option>
          </select>
        ) : (
          <select
            className="rounded w-full py-1.5 border border-gray"
            onChange={onChangeSegmentHandler}
            value={segment}
          >
            {segmentDropDown?.map((option, index) => (
              <option
                style={{ alignItems: "center" }}
                key={index}
                value={option.ID}
              >
                {option.Desc}
              </option>
            ))}
          </select>
        )}
      </div>
      <div
       className="mt-3"
      >
        <label>Family: </label>
        {familyDropDownOptions.length === 0 ? (
          <select className="rounded w-full py-1.5 border border-gray" disabled>
            <option value="">-- SELECT --</option>
          </select>
        ) : (
          <select
            className="rounded w-full py-1.5 border border-gray"
            onChange={onChangeFamilyHandler}
            disabled={!segment}
            // checking if there is an initial value if one is present the select
            // the correct option from the dropdown
            value={family}
          >
            {familyDropDownOptions?.map((option, index) => (
              <option
                style={{ alignItems: "center" }}
                key={index}
                value={option.ID}
              >
                {option.Desc}
              </option>
            ))}
          </select>
        )}
      </div>
      <div className="mt-3">
        <label>Class:</label>
        {classDropDownOptions.length === 0 ? (
          <select className="rounded w-full py-1.5 border border-gray" disabled>
            <option value="">-- SELECT --</option>
          </select>
        ) : (
          <select
            className="rounded w-full py-1.5 border border-gray"
            onChange={onchangeClassHandler}
            disabled={!family}
            value={classs || initialClassValue}
          >
            {classDropDownOptions?.map((option, index) => (
              <option
                style={{ alignItems: "center" }}
                key={index}
                value={option.ID}
              >
                {option.Desc}
              </option>
            ))}
          </select>
        )}
      </div>
      <div
        className="mt-3"
      >
        <label>Brick:</label>
        {brickDropDownOptions.length === 0 ? (
          <select className="rounded w-full py-1.5 border border-gray" disabled>
            <option value="">-- SELECT --</option>
          </select>
        ) : (
          <select
            className="rounded w-full py-1.5 border border-gray"
            onChange={onChangeBrickHandler}
            disabled={!classs}
            value={brick || initialBrickValue}
            ref={selectedBrickRef}
          >
            {brickDropDownOptions?.map((option, index) => (
              <option
                style={{ alignItems: "center", width: "200px" }}
                key={index}
                value={option.ID}
              >
                {option.Desc}
              </option>
            ))}
          </select>
        )}
      </div>
      <div className="mt-8">
        <div
        >
          <label>Grouping:</label>
          {groupingDropDownOptions.length === 0 ? (
            <select className="rounded w-full py-1.5 border border-gray" disabled>
              <option value="">-- SELECT --</option>
            </select>
          ) : (
            <select
              className="rounded w-full py-1.5 border border-gray"
              onChange={onChangeGroupingHandler}
              disabled={!brick}
              value={grouping || initialGroupingValue}
              ref={groupingSelectedRef}
            >
              {groupingDropDownOptions?.map((option, index) => (
                <option
                  style={{ alignItems: "center", width: "200px" }}
                  key={index}
                  value={option.ID}
                >
                  {option.Desc}
                </option>
              ))}
            </select>
          )}
        </div>
        <div className="mt-3">
          <label>Variant:</label>
          {variantDropDownOptions.length === 0 ? (
            <select className="rounded w-full py-1.5 border border-gray" disabled>
              <option value="">-- SELECT --</option>
            </select>
          ) : (
            <select
              className="rounded w-full py-1.5 border border-gray"
              onChange={onChangeVariantHandler}
              disabled={!grouping}
              value={variant || initialVariantValue}
            >
              {variantDropDownOptions?.map((option, index) => (
                <option
                  style={{ alignItems: "center", width: "200px" }}
                  key={index}
                  value={option.ID}
                >
                  {option.Desc}
                </option>
              ))}
            </select>
          )}
        </div>
        <div className="mt-3">
          <label>Detail:</label>
          {detailDropDownOptions.length === 0 ? (
            <select className="rounded w-full py-1.5 border border-gray" disabled>
              <option value="">-- SELECT --</option>
            </select>
          ) : (
            <select
              className="rounded w-full py-1.5 border border-gray"
              onChange={onChangeDetailHandler}
              disabled={!variant}
              value={detail || initialDetailValue}
            >
              {detailDropDownOptions?.map((option, index) => (
                <option
                  style={{ alignItems: "center", width: "200px" }}
                  key={index}
                  value={option.ID}
                >
                  {option.Desc}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>
      {message ? (
        <div
          className="mt-3"
        >
          <div>
            <p style={{ color: "red", fontWeight: "bold" }}>
              please provide a Brick
            </p>
          </div>
        </div>
      ) : null}
        <div className="flex gap-3 mt-3 justify-end items-center">
          <button className="bg-gray-500 rounded text-white py-1.5 px-3" onClick={() => closeClassifyModal()}>Cancel</button>
          <button className="bg-primary rounded text-white py-1.5 px-3" onClick={() => onClickConfirm()}>Confirm</button>
      </div>
    </div>
  );
};

export default ReclassifyDropdownsModal;
