import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormFieldOnChange } from "../../utils/FormHooks";
import { useAppSelector, useAppDispatch } from "../../store/store";
import {
	auth,
	setSucceeded,
	setError,
	setProcessing,
	setPwDontMatch,
	newAccount, 
	resetState,
} from "../../store/slices/authSlice";

import "./SignUpPage.css";
import AuthPrompt from "../../components/AuthPrompt";
import ReCaptcha from "../../components/reCaptcha";

const SignUpPage = () => {
	const regExpUpper = /[A-Z]/;
	const regExpNumber = /\d/;
	const regExLength = /^.{8,25}$/;
	const dispatch = useAppDispatch();
	const { succeeded, processing, error, pwDontMatch } = useAppSelector(auth);
	const navigate = useNavigate();
	const [nameInput, setNameInput, onNameChange] = useFormFieldOnChange("");
	const [emailInput, setEmailInput, onEmailChange] = useFormFieldOnChange("");
	const [passwordInput, setPasswordInput, onPasswordChange] = useFormFieldOnChange("");
	const [confirmPwInput, setConfirmPwInput, onConfirmPwChange] = useFormFieldOnChange("");
	const [selectedState, setSelectedState] = useState(""); // State for selected state dropdown
	const [numbermet, setNumbermet] = useState(false);
	const [charmet, setCharmet] = useState(false);
	const [uppermet, setUppermet] = useState(false);
	const [confirmPasswordMatch, setConfirmPasswordMatch] = useState('');
	const [signupEnabled, setSignupEnabled] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const firstNameRef = useRef(null);

	// ReCaptcha
	const [token, setToken] = useState('');
	const [submitEnabled, setSubmitEnabled] = useState(false);

	useEffect(() => {
		if(token.length){
			setSubmitEnabled(true)
		}
	}, [token]);

	const handleToken = () => {
		setToken(token)
	};

	const togglePasswordVisibility = () => {
		setShowPassword(prev => !prev);
	};
	const toggleConfirmPasswordVisibility = () => {
		setShowConfirmPassword(prev => !prev);
	};

	const validateEmail = (email) => {
        // Regular expression for email validation
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const handleEmailBlur = () => {
        if (!validateEmail(emailInput)) {
            dispatch(setError('Invalid email address entered'));
        } else {
            dispatch(setError(''));
        }
    };

	const conditionCheck = (Password, ConfirmPassword) => {
		setPasswordInput(Password);
		setConfirmPwInput(ConfirmPassword);

		if (!Password && !ConfirmPassword) {
			setConfirmPasswordMatch(false);
			setUppermet(false);
			setNumbermet(false);
			setCharmet(false);
			return; // Exit early to avoid further checks
		}

		if (Password.match(regExpUpper)) {
			setUppermet(true);
		} else {
			setUppermet(false);
		}

		if (Password.match(regExpNumber)) {
			setNumbermet(true);
		} else {
			setNumbermet(false);
		}

		if (Password.match(regExLength)) {
			setCharmet(true);
		} else {
			setCharmet(false);
		}

		if (Password === ConfirmPassword) {
			setConfirmPasswordMatch(true);
		} else {
			setConfirmPasswordMatch(false);
		}
	};

	useEffect(() => {
		conditionCheck(passwordInput, confirmPwInput);

		if (nameInput && emailInput && selectedState && confirmPasswordMatch && numbermet && charmet && uppermet && submitEnabled) {
			setSignupEnabled(true);
		} else {
			setSignupEnabled(false);
		}
	}, [nameInput, emailInput, passwordInput, selectedState, confirmPwInput, confirmPasswordMatch, numbermet, charmet, uppermet, submitEnabled]);

	useEffect(() => {
		// Focus the input when the component mounts
		if (firstNameRef.current) {
			firstNameRef.current.focus();
		}
	  }, []);

	const hideStatusMessages = () => {
		dispatch(setProcessing(false));
		// dispatch(setError(""));
		//dispatch(setPwDontMatch(false));
	};

	const resetForm = () => {
		setNameInput("");
		setEmailInput("");
		setSelectedState(""); 
		setPasswordInput("");
		setConfirmPwInput("");
		hideStatusMessages();
	};

	const onInputChange = (e, inputHandler) => {
		inputHandler(e);
		dispatch(setError(""));
		dispatch(setSucceeded(false));
		hideStatusMessages();
	};


	// useEffect(() => {
	// 	if (passwordInput.trim() === "" || confirmPwInput.trim() === "") {
	// 		dispatch(setPwDontMatch(false));
	// 		return;
	// 	}
	// 	dispatch(setPwDontMatch(passwordInput !== confirmPwInput));
	// }, [passwordInput, confirmPwInput, error, succeeded]);

	const submitForm = async (e) => {
		dispatch(setError(""));
		e.preventDefault();
		if (processing) {
			return;
		}

		if (nameInput.trim() === "" || emailInput.trim() === "" || selectedState.trim() === "") {
			const nameErrorMessage = nameInput.trim() === "" ? "Please Enter Your First Name" : "";
			const emailErrorMessage = emailInput.trim() === "" ? "Please Enter Your Email" : "";
			const stateErrorMessage = selectedState.trim() === "" ? "Please Select Your State" : ""; // Add state error message
			const errorMessage = nameErrorMessage || emailErrorMessage || stateErrorMessage; // Include state error message
			//const errorMessage = nameErrorMessage || emailErrorMessage;
			dispatch(setError(errorMessage));
			return;
		}

		// if (passwordInput !== confirmPwInput) {
		// 	dispatch(setPwDontMatch(true));
		// 	return;
		// }

		await dispatch(newAccount(nameInput, emailInput, passwordInput, "M", selectedState));
		// dispatch(setSucceeded(true));
		// if (succeeded) {
		// 	resetForm();
		// }
	};

	const continueAfterRegistration = (e) => {
		e.preventDefault();
		navigate("/");
	};

	const accountCreated = () => (
		<>
			<p style={{ fontWeight: "normal" }} className="text-black text-center">
				Check your email and follow the instructions to verify your account.
			</p>
			<div className="text-center"> {/* Center the content */}
				<button
					className={"bg-primary rounded-full text-white py-1.5 w-1/4 mt-2"}
					onClick={(e) => {
						dispatch(resetState());
						continueAfterRegistration(e);
					}}
				>
					Continue
				</button>
			</div>
		</>

	);

	const formContent = () => (
		<>
			<div className="flex flex-col mb-3">
				<label>First Name</label>
				<input
					type="text"
					ref={firstNameRef}
					placeholder="Enter your name..."
					value={nameInput}
					className="rounded-full border py-1.5 px-3"
					onChange={(e) => onInputChange(e, onNameChange)}
				/>
			</div>
			<div className="flex flex-col mb-3">
				<label>Email</label>
				<input
					type="text"
					placeholder="Enter your email..."
					value={emailInput}
					className="rounded-full border py-1.5 px-3"
					onChange={(e) => onInputChange(e, onEmailChange)}
					onBlur={handleEmailBlur}
				/>
				 
			</div>
			<div className="flex flex-col mb-3" >
				<label>State</label>
				<select
					value={selectedState}
					onChange={(e) => setSelectedState(e.target.value)}
					className="rounded-full border py-1.5 px-2 w-full"
					style={{ color: selectedState ? 'black' : '#9ba3af'  }}
				>
					<option value="">Enter your state...</option>
					<option value="ACT">Australian Capital Territory</option>
					<option value="NSW" >New South Wales</option>
					<option value="NT">Northern Territory</option>
					<option value="QLD">Queensland</option>
					<option value="SA">South Australia</option>
					<option value="TAS">Tasmania</option>
					<option value="VIC">Victoria</option>
					<option value="WA">Western Australia</option>
				</select>
			</div>
			<div className="flex flex-col mb-3">
				<label>Password</label>
				<div className="relative rounded-full border">
					<input
						type={showPassword ? 'text' : 'password'}
						placeholder="Enter your password..."
						value={passwordInput}
						className="rounded-full py-1.5 px-3 w-full "  // Adjusted padding to make room for the show/hide toggle
						onChange={(e) => {
							onInputChange(e, onPasswordChange);
							conditionCheck(e.target.value, confirmPwInput);
						}}
					/>
					<span
						className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
						onClick={togglePasswordVisibility}
						>
						{/* {showPassword ? 'Hide' : 'Show'} */}
						<img 
							src={showPassword ? require('../../assets/images/Eye_Show.png') : require('../../assets/images/Eye_Hide.png')}
							alt={showPassword ? "Hide Password" : "Show Password"}
							style={{ width: '20px', height: '20px' }} // Adjust size as needed
						/>
					</span>
				</div>
			</div>
			<div>
				<div style={{ display: 'flex', alignItems: 'center' , marginBottom: 1, marginLeft: 4}}>
					{!charmet ? (
						<img
						src={require('../../assets/images/Cross_Red_Circle.png')}
						style={{ width: 15, height: 15, marginRight: 5 }}
						alt="Cross"
						/>
					) : (
						<img
						src={require('../../assets/images/White_Heavy_Check_Mark.png')}
						style={{ width: 15, height: 15, marginRight: 5 }}
						alt="Check"
						/>
					)}
					<span>Eight or more characters (Max 25)</span>
				</div>
				<div style={{ display: 'flex', alignItems: 'center', marginBottom: 1, marginLeft: 4 }}>
					{!uppermet ? (
						<img
						src={require('../../assets/images/Cross_Red_Circle.png')}
						style={{ width: 15, height: 15, marginRight: 5 }}
						alt="Cross"
						/>
					) : (
						<img
						src={require('../../assets/images/White_Heavy_Check_Mark.png')}
						style={{ width: 15, height: 15, marginRight: 5 }}
						alt="Check"
						/>
					)}
					<span>At least one (1) Uppercase character</span>
				</div>

					{/* Condition for at least one numeric character */}
				<div style={{ display: 'flex', alignItems: 'center', marginBottom: 1, marginLeft: 4 }}>
					{!numbermet ? (
						<img
						src={require('../../assets/images/Cross_Red_Circle.png')}
						style={{ width: 15, height: 15, marginRight: 5 }}
						alt="Cross"
						/>
					) : (
						<img
						src={require('../../assets/images/White_Heavy_Check_Mark.png')}
						style={{ width: 15, height: 15, marginRight: 5 }}
						alt="Check"
						/>
					)}
					<span>At least one (1) Numeric character</span>
				</div>

					{/* Condition for confirming password match */}
				<div style={{ display: 'flex', alignItems: 'center', marginBottom: 6, marginLeft: 4}}>
					{!confirmPasswordMatch ? (
						<img
						src={require('../../assets/images/Cross_Red_Circle.png')}
						style={{ width: 15, height: 15, marginRight: 5 }}
						alt="Cross"
						/>
					) : (
						<img
						src={require('../../assets/images/White_Heavy_Check_Mark.png')}
						style={{ width: 15, height: 15, marginRight: 5 }}
						alt="Check"
						/>
					)}
					<span>Confirm Password Match</span>
				</div>
			</div>

			<div className="flex flex-col mb-3">
				<label>Confirm Password</label>
				<div className="relative rounded-full border">
					<input
						type={showConfirmPassword ? 'text' : 'password'}
						placeholder="Confirm your password..."
						className="rounded-full py-1.5 px-3 w-full "
						value={confirmPwInput}
						onChange={(e) => onInputChange(e, onConfirmPwChange)}
					/>
					<span
						className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
						onClick={toggleConfirmPasswordVisibility}
					>
						<img 
							src={showConfirmPassword ? require('../../assets/images/Eye_Show.png') : require('../../assets/images/Eye_Hide.png')}
							alt={showConfirmPassword ? "Show Password" : "Hide Password"}
							style={{ width: '20px', height: '20px' }} // Adjust size as needed
						/>
					</span>
				</div>
			</div>
			{/* {error && (
				<div className="text-red-500 font-bold text-center md:text-left">
					{error}
				</div>
			)} */}
			{error ? (
				<div className="text-red-500 mb-2 font-bold text-center md:text-left ml-2">
					{error}
				</div>
			) : (
				<div className="text-500 mb-10 font-bold bg-white text-center md:text-left"/>
			)}
			{/* {pwDontMatch && (
				<div className="text-red-500 font-bold text-center md:text-left">
					You've entered different passwords!
				</div>
			)} */}
			{succeeded && (
				<div className="text-red-500 font-bold text-center md:text-left">
					Congratulations, your account has been created.
					<br />
					Please, read the instructions we've sent to your email on how to activate your account.
				</div>
			)}
			<div className="mb-10 justify-center align-center flex">
				<ReCaptcha 
					sitekey="6LfDksgqAAAAAHNYkgJvsjpb5uZAesNWXV-Ruku7"
					callback={(token) => {
						setToken(token);
						setSubmitEnabled(true);
					}} 
			/>
			</div>
			<div className="flex align-center gap-2">
				<button
					disabled={processing}
					className={"bg-gray-500 rounded-full text-white py-1.5 w-full"}
					onClick={(e) => {
						e.preventDefault();
						resetForm();
					}}
				>
					Reset Screen
				</button>
				<button
					className={"bg-primary rounded-full text-white py-1.5 w-full"}
					disabled={!signupEnabled}
					style={{opacity: !signupEnabled ? 0.4 : 1 }}
					onClick={(e) => {
						dispatch(setProcessing(true));
						submitForm(e).then(r => {
							hideStatusMessages();
						});
					}}
				>
					Confirm
				</button>

			</div>
			<div className="text-right mt-3">
				<Link to="/SignIn">Have an Account? Login here</Link>
			</div>
		</>
	);

	return (
		<div className="w-full flex justify-center h-full items-center overflow-hidden">
			<div className="lg:max-w-lg md:max-w-lg w-full mx-auto bg-white px-4 pb-3 rounded-sm shadow-xl">
				<form>
					<div className="flex flex-col justify-center items-center">
						<img className="w-[300px]" src="BBA-Logo-TBl-S.png" alt="BBA-Logo-TBl-S" />
						<p className="font-bold text-center mb-3 text-lg">
							{!succeeded ? "Create your account" : "Account created"}
						</p>
					</div>
					{succeeded ? accountCreated() : formContent()}
				</form>
			</div>
			<AuthPrompt open={error === 'The email has already been taken.' ? true : false} requestType={'SignUp'} payload={{ firstname: nameInput, email: emailInput, id: 0 }} />
		</div>
	);
};

export default SignUpPage;
